<template>
  <div id="app">
    <router-view :shouldChangeCity="shouldChangeCity" :cityName="cityName" v-if="isRouterAlive"/>
  </div>
</template>

<style>
body {
  font-size: 16px;
  background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
}
</style>

<style lang="scss">
    @import "../static/css/base.css";
    

    #app {
        .app-router {
            margin-top: 68px;
            position: absolute;
            bottom: 0;
            top: 0;
            background-color: #F5F4F9;
        }
    }
</style>

<script>
export default{
  name:'app',
  provide(){
    return{     
      reload:this.reload
    }
  },
  data(){
    return{
      cityName: '请选择',
      shouldChangeCity: true,
      isRouterAlive:true
    }
  },
  methods:{
    reload(){
      this.isRouterAlive = false
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    }
  }
}
</script>