import 'amfe-flexible';
import Vue from 'vue';
import App from './App';
import { router } from './router';
import VueSocketIO from 'vue-socket.io'
import { Button } from 'vant';
import { Col, Row } from 'vant';
import { Dialog} from 'vant';

// 注册
Vue.use(
  new VueSocketIO({
    debug: false ,   // debug调试，生产关闭
    // url = 'http://114.132.243.61:8801/
    // url = '/
    //connection:'http://114.132.243.61:8801/chat',
    connection:'/chat',
    options:{autoConnect:false}
  })
)

Vue.use(Button);
Vue.use(Col);
Vue.use(Row);
Vue.use(Dialog);

new Vue({
  // 这里为全局监听socket事件消息，不需要全局可以放到组件里面去。
  router,
  el: '#app',
  render: h => h(App)
});

