import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/index'
  },
  {
    name: 'user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心'
    }
  },
  {
    name: 'cart',
    component: () => import('./view/cart'),
    meta: {
      title: '购物车'
    }
  },
  {
    name: 'goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情'
    }
  },
  {
    name: 'index',
    component: () => import('./view/index'),
    meta: {
      title: 'none'
    }
  },
  {
    path: '/aa/:biz/:shop/:token',
    name: 'Describe',
    component: () => import('./view/aa'),
    meta: {
      title: 'token-aa'
    }
  },
  {
    path: '/:biz/:shop/:token/aa',
    name: 'Describe',
    component: () => import('./view/aa'),
    meta: {
      title: 'token-aa'
    }
  },
  {
    path: '/kfc-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-zs99-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/zs99-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-zs120-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/zs120-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus1-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus1-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus2-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus2-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus3-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus3-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus4-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus4-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus5-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus5-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus6-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus6-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus7-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus7-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus8-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus8-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-cus9-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/cus9-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-wm-2w5/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wm-2w5'),
    meta: {
      title: 'kfc-wm-2w5'
    }
  },
  {
    path: '/kfc-wm-4w/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wm-4w'),
    meta: {
      title: 'kfc-wm-4w'
    }
  },
  {
    path: '/kfc-wm-5w/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wm-5w'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-wm-z4/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wm-z4'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-zc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/zc'),
    meta: {
      title: 'kfc-zc'
    }
  },
  {
    path: '/kfc-xwc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/xwc'),
    meta: {
      title: 'kfc-xwc'
    }
  },  
  {
    path: '/kfc-kf/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/kf'),
    meta: {
      title: 'kfc-kf'
    }
  },  
  {
    path: '/kfc-kf-xwc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/kf-xwc'),
    meta: {
      title: 'kfc-xwc'
    }
  },
  {
    path: '/kfc-kf-kf/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/kf-kf'),
    meta: {
      title: 'kfc-kf'
    }
  },
  {
    path: '/kfc-dt-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/dt-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-dt-xwc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/dt-xwc'),
    meta: {
      title: 'kfc-xwc'
    }
  },
  {
    path: '/kfc-dt-kf/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/dt-kf'),
    meta: {
      title: 'kfc-kf'
    }
  },
  {
    path: '/kfc-wc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/wc'),
    meta: {
      title: 'kfc-wc'
    }
  }, 
  {
    path: '/kfc-yw/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-zs99-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/zs99-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-zs120-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/zs120-wm'),
    meta: {
      title: 'kfc-wm'
    }
  },
  {
    path: '/kfc-q-yw/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/q-yw'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-q-hb/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/q-hb'),
    meta: {
      title: 'kfc-hb'
    }
  },
  {
    path: '/kfc-q-zc/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/q-zc'),
    meta: {
      title: 'kfc-zc'
    }
  },
  {
    path: '/kfc-hb/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb'),
    meta: {
      title: 'kfc-hb'
    }
  },
  {
    path: '/kfc-hb-1m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-1m'),
    meta: {
      title: 'kfc-hb'
    }
  },
  {
    path: '/kfc-hb-2m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-2m'),
    meta: {
      title: 'kfc-hb'
    }
  },
  {
    path: '/kfc-hb-3m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-3m'),
    meta: {
      title: 'kfc-hb'
    }
  },  
  {
    path: '/kfc-hb-4m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-4m'),
    meta: {
      title: 'kfc-hb'
    }
  },
  {
    path: '/kfc-hb-5m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-5m'),
    meta: {
      title: 'kfc-hb'
    }
  }, 
  {
    path: '/kfc-hb-6m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/hb-6m'),
    meta: {
      title: 'kfc-hb'
    }
  },  
  {
    path: '/kfc-yw-1m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-1m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-yw-2m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-2m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-yw-3m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-3m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-yw-4m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-4m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-yw-5m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-5m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
    path: '/kfc-yw-6m/:shop/:token',
    name: 'Describe',
    component: () => import('./view/kfc/yw-6m'),
    meta: {
      title: 'kfc-yw'
    }
  },
  {
      path: '/kfc-city',
      name: 'kfc-cityList',
      component: () => import('./view/kfc/city/cityList.vue'),
    meta: {
      title: 'kfc-yw'
    }
  }, 
  {
    path: '/md-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/md/wm'),
    meta: {
      title: 'md-wm'
    }
  },
  {
    path: '/bsk-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-zc-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/zc-wm'),
    meta: {
      title: 'bsk-zc-wm'
    }
  }, 
  {
    path: '/bsk-cus1-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus1-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus2-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus2-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus3-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus3-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus4-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus4-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus5-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus5-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus6-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus6-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus7-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus7-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus8-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus8-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-cus9-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/cus9-wm'),
    meta: {
      title: 'bsk-wm'
    }
  },
  {
    path: '/bsk-zc-ts/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/zc-ts'),
    meta: {
      title: 'bsk-zc-ts'
    }
  }, 
  {
    path: '/bsk-zc-qr/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/zc-qr'),
    meta: {
      title: 'bsk-zc-qr'
    }
  }, 
  {
    path: '/bsk-kf/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/kf'),
    meta: {
      title: 'bsk-kf'
    }
  },
  {
    path: '/bsk-yw/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/yw'),
    meta: {
      title: 'bsk-yw'
    }
  },
  {
    path: '/bsk-hb/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bsk/hb'),
    meta: {
      title: 'bsk-hb'
    }
  },
  {
    path: '/sms-kfc-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/kfc/wm'),
    meta: {
      title: 'sms-kfc-wm'
    }
  },
  {
    path: '/sms-kfc-dd/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/kfc/dd'),
    meta: {
      title: 'sms-kfc-dd'
    }
  },
  {
    path: '/sms-md-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/md/wm'),
    meta: {
      title: 'sms-md-wm'
    }
  },
  {
    path: '/sms-md-dd/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/md/dd'),
    meta: {
      title: 'sms-md-dd'
    }
  },
  {
    path: '/sms-bsk-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/bsk/wm'),
    meta: {
      title: 'sms-bsk-wm'
    }
  },
  {
    path: '/sms-bsk-dd/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/bsk/dd'),
    meta: {
      title: 'sms-bsk-dd'
    }
  },
  {
    path: '/sms-xbk-wm/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/xbk/wm'),
    meta: {
      title: 'sms-xbk-wm'
    }
  },
  {
    path: '/sms-xbk-dd/:shop/:token',
    name: 'Describe',
    component: () => import('./view/sms/xbk/dd'),
    meta: {
      title: 'sms-xbk-dd'
    }
  },
  {
    path: '/bd/:type/:shop/:token',
    name: 'Describe',
    component: () => import('./view/bd'),
    meta: {
      title: 'bd'
    }
  },
  {
    path: '/wps/:type/:shop/:token',
    name: 'Describe',
    component: () => import('./view/wps'),
    meta: {
      title: 'wps'
    }
  },
  {
    path: '/xt/:type/:shop/:token',
    name: 'Describe',
    component: () => import('./view/xt'),
    meta: {
      title: 'xt'
    }
  }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ 
  mode:'hash', // remove # in url
routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
